(function () {
  "use strict";

  $(document).ready(function () {
  
  // Block eventi
  $('.container-slider-eventi').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false,
    dots:false,
    mobileFirst:true,
    responsive: [
         {
        breakpoint:520,
        settings: {
            slidesToShow: 2,
        }
      },
      {
        breakpoint:1023,
        settings: {
          arrows:true,
        }
      }
    ]
 });

 // Block Card Ico

   $('.slider-cards').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false,
    mobileFirst:true,
    responsive: [
      {
        breakpoint:650,
        settings: {
          slidesToShow: 2,
        }
      },
       {
        breakpoint:900,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint:1199,
        settings: 'unslick',
      }
    ]
 });

 // Block section slider

  $('.slider-section').slick({
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:true,
    dots:true,
 });

  // Block section slider gallery slider



  $('.slider-gallery').slick({
    arrows:false,
    dots:false,
    autoplay:true,
    centerMode: true,
    centerPadding: '0px',
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1
        }
      }
    ]
  });








  }); // close document get ready
})(jQuery);