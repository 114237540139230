let cursor= $('.cursor');
let cursorArea = $('.show-cursor')

gsap.set(cursor, { opacity: 0});

cursorArea.each((index,item)=>{
  let $this = $(item);
  $this.on('mouseenter', (e)=> {
    $this.addClass('hovering');
      if($this.hasClass('hovering')){
         positionCircle(e);
          TweenMax.to('.cursor', 0.2, {autoAlpha: 1})
      }
  }).on('mouseleave', (e)=> {
     $this.removeClass('hovering');
     positionCircle(e);
      TweenMax.to('.cursor', 0.2, {autoAlpha: 0})
     })
  $this.on('mousemove', (e)=> {
     positionCircle(e);
 })
})


const positionCircle = (e) => {
  let x = e.pageX;
  let y = e.pageY;
  if(!$('body.home-page').length){
    //horizontal scroll
    x = e.clientX;
    y = e.clientY;
  }

  let w = $('.cursor').innerWidth();
  TweenMax.to('.cursor', 0.2, {x: x - (w/2), y: y - (w/2)})

}