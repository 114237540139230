(function () {
  "use strict";

  $(document).ready(function () {

    // Mobile menu
    $(".menu-toggle").on("click", function () {
      $(".menu-mobile").addClass("menu-mobile-open");
    });
    $(".menu-mobile-close, .menu-item").on("click", function () {
      $(".menu-mobile").removeClass("menu-mobile-open");
    });

    // Navbar fixed scroll up
    var previousScroll = 0;
    $(window).scroll(function() {
        var currentScroll = $(this).scrollTop();
            
        // Verifica se l'utente sta scorrendo verso l'alto
        if (currentScroll < previousScroll) {
        // Calcola la percentuale di scroll verso l'alto
        var scrollPercentage = (currentScroll / previousScroll) * 100;
            
        // Mostra la navbar fissa se la percentuale di scroll verso l'alto è maggiore di zero
        if (scrollPercentage > 0) {
            $('.site-header').addClass('fixed');
        } else {
            // Rimuovi la classe "fixed" se la percentuale di scroll verso l'alto è zero
            $('.site-header').removeClass('fixed');
        }
        } else {
        // Nascondi la navbar fissa se l'utente sta scorrendo verso il basso
        $('.site-header').removeClass('fixed');
        }
            
        previousScroll = currentScroll;
      });




  }); // close document get ready
})(jQuery);